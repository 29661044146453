<template>
  <div class="he-ui-statistics-wrapper w-100 h-100">
    <div
      v-for="(phase, index) in phases"
      :key="index"
      class="d-flex flex-column align-items-center h-100">
      <Phase
        :data="phase"
        showTimeCircle
        showButton
        :onClickHandler="goToReport" />
    </div>
  </div>
</template>

<script>
import { STATISTICS_DETAILS_PAGE_ROUTE_NAME } from '@/router/routes'
import Phase from '@/components/Phase'

export default {
  components: {
    Phase
  },
  data () {
    return {
      // Save phases in vuex
      phases: [
        {
          id: 1,
          active: true,
          value: '30',
          src: require('../../assets/statistics/admision.svg'),
          alt: 'admission',
          tag: this.$t('main.admission')
        },
        {
          id: 2,
          active: true,
          value: '20',
          src: require('../../assets/statistics/quirofano.svg'),
          alt: 'operating_room',
          tag: this.$t('main.operating_room')
        },
        {
          id: 3,
          active: true,
          value: '60',
          src: require('../../assets/statistics/postoperatorio.svg'),
          alt: 'posoperative',
          tag: this.$t('main.postoperative')
        }
      ]
    }
  },
  methods: {
    goToReport (id) {
      this.$router.push({
        name: STATISTICS_DETAILS_PAGE_ROUTE_NAME,
        params: { phase: id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-statistics-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10%;
}

</style>
